import {Guid, ParticipantInfo, SurveyAnswer} from "@careevolution/mydatahelps-js"
import {TestResult} from "../types/TestResult";

const moment = require('moment');

let tryParseJSON = function (json: string, defaultValue: any): any {
    if (json) {
        return JSON.parse(json.replace(/\\/g, ""));
    }
    return defaultValue;
};

let getReadStatus = function (participantInfo: ParticipantInfo, surveyResultID: Guid) {
    let mappings = tryParseJSON(participantInfo.customFields["TestResultUUIDMap"], {});
    let testStripImageExpertRead = tryParseJSON(participantInfo.customFields["TestStripImageExpertRead"], {});
    let testStripImageReportIDs = tryParseJSON(participantInfo.customFields["TestStripImageReportIDs"], []);
    let taskRunUUID = mappings[surveyResultID.toString()];
    if (taskRunUUID) {
        let report = testStripImageReportIDs.find(function (r: any) {
            return r.taskRunUUID == taskRunUUID;
        });
        if (report && testStripImageExpertRead[report.id] && testStripImageExpertRead[report.id].result) {
            return testStripImageExpertRead[report.id].result;
        }
    }
    return null;
}

export default class TestResultAssembler {
    static assembleTestResults = function (participantInfo: ParticipantInfo, answers: SurveyAnswer[]): TestResult[] {
        let sorted = answers.sort(function (answer1, answer2) {
            return moment(answer1.date).diff(moment(answer2.date));
        });

        let testResults: TestResult[] = [];

        let currentTestResult: TestResult | null = null;
        for (let i = 0; i < sorted.length; i++) {
            let currentAnswer = sorted[i];
            if (currentAnswer.stepIdentifier === 'Condensed_AddTestStrip' || currentAnswer.stepIdentifier === 'Full_AddTestStrip') {
                currentTestResult = {testStripAddTime: currentAnswer.date};
                testResults.push(currentTestResult);
            }
            if ((currentAnswer.stepIdentifier === 'SelfInterpret' || currentAnswer.stepIdentifier === 'TestResult') && currentAnswer.answers[0] !== 'Unconfirmed') {
                if (currentTestResult && !currentAnswer.surveyName.startsWith('TestResultAdd')) {
                    currentTestResult.result = currentAnswer.answers[0];
                    let readStatus = getReadStatus(participantInfo, currentAnswer.surveyResultID);
                    if (readStatus) {
                        currentTestResult.result = readStatus;
                        currentTestResult.confirmed = true;
                    }
                } else if (currentAnswer.surveyName.startsWith('TestResultAdd')) {
                    let result: TestResult = {
                        testStripAddTime: currentAnswer.date,
                        result: currentAnswer.answers[0]
                    };

                    let readStatus = getReadStatus(participantInfo, currentAnswer.surveyResultID);
                    if (readStatus) {
                        result.result = readStatus;
                        result.confirmed = true;
                    }
                    testResults.push(result);
                }
            }
        }

        return testResults;
    };
}