import React, {useEffect, useState} from "react";
import './TestResultRow.css'
import '../Core.css'
import {TestResult} from "../types/TestResult";
import MyDataHelps, {Guid, ParticipantInfo, SurveyAnswer} from "@careevolution/mydatahelps-js";
import testResultAssembler from "../helpers/TestResultAssembler";
import TestResultRow from "./TestResultRow";

const moment = require('moment');

export interface TestResultListProps {
    participantInfo?: ParticipantInfo;
    maxResults?: number;
}

export default function (props: TestResultListProps) {
    if (!props.participantInfo) return null;

    const [testResults, setTestResults] = useState<TestResult[]>();

    const loadTestResults = (): void => {
        let answers: SurveyAnswer[] = [];
        let getSurveyAnswersPage = function (pageID?: Guid): Promise<TestResult[]> {
            return MyDataHelps.querySurveyAnswers({
                stepIdentifier: ["Condensed_AddTestStrip", "Full_AddTestStrip", "TestResult", "SelfInterpret"],
                pageID: pageID
            }).then(function (response) {
                answers = answers.concat(response.surveyAnswers);
                if (response.nextPageID) {
                    return getSurveyAnswersPage(response.nextPageID);
                } else {
                    return testResultAssembler.assembleTestResults(props.participantInfo!, answers);
                }
            });
        };

        getSurveyAnswersPage().then(function (testResults) {
            setTestResults(testResults);
        });
    };

    useEffect(() => {
        loadTestResults();
    }, []);

    if (!testResults) return null;

    let resultsToRender = testResults.filter(r => r.result).sort((a: TestResult, b: TestResult) => {
        return moment(b.testStripAddTime).diff(moment(a.testStripAddTime))
    });

    if (props.maxResults) {
        resultsToRender = resultsToRender.slice(0, Math.min(props.maxResults, resultsToRender.length));
    }

    if (resultsToRender.length <= 0) return null;

    return <div className="test-result-list">
        {resultsToRender.map((testResult, index) =>
            <TestResultRow testResult={testResult} key={index}/>
        )}
    </div>;
}