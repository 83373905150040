import React from 'react';
import ReactDOM from 'react-dom';
import MyDataHelps from '@careevolution/mydatahelps-js';
import '@careevolution/symptom-shark'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './views/Home';
import Homev3 from './views/Homev3';
import {CalendarView, getDayKey, SetupStep, SymptomDetailView} from '@careevolution/symptom-shark';
import language from './helpers/Language';

import Day from './views/Day';
import TestResults from './views/TestResults';
import MyAchievements from './views/MyAchievements';
import MyAchievementsv3 from './views/MyAchievementsv3';

if (window.location.hostname === 'localhost' && !!process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN) {
    MyDataHelps.setParticipantAccessToken({'access_token': process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN!, 'expires_in': 21600, 'token_type': 'Bearer'}, 'https://mydatahelps.org/');
}

function selectDay(date: Date) {
    MyDataHelps.openApplication(new URL('/day/?date=' + getDayKey(date), document.location as any).href);
}

function selectSymptom(s: String, y: Number, m: Number) {
    MyDataHelps.openApplication(new URL('/symptom/?month=' + m + '&year=' + y + '&symptom=' + s, document.location as any).href, {modal: true});
}

if (document.getElementById('root')) {
    ReactDOM.render(
        <React.StrictMode>
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/home/" element={<Home/>}/>
                    <Route path="/v3/home/" element={<Homev3/>}/>
                    <Route path="/test_results/" element={<TestResults/>}/>
                    <Route path="/calendar/" element={<CalendarView
                        onDaySelected={(d) => selectDay(d)}
                        onSymptomSelected={(s, y, m) => selectSymptom(s, y, m)}/>}/>
                    <Route path="/day/" element={<Day/>}/>
                    <Route path="/symptom/" element={<SymptomDetailView/>}/>
                    <Route path="/achievements/" element={<MyAchievements/>}/>
                    <Route path="/v3/achievements/" element={<MyAchievementsv3/>}/>
                </Routes>
            </Router>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

function router(rootClassName: string) {
    switch (rootClassName) {
        case "symptom-setup":
            return <SetupStep
                title={language["symptom-setup-title"]}
                description={language["symptom-setup-description"]}
                doneButtonText={language["symptom-setup-done"]}
                initialSetup={true}
                requireItems={true}
                inputPlaceholder={language["symptom-setup-placeholder"]}
                concept="Symptoms"
                stepIdentifier="Symptoms"/>
        case "treatment-setup":
            return <SetupStep
                title={language["treatment-setup-title"]}
                description={language["treatment-setup-description"]}
                doneButtonText={language["treatment-setup-done"]}
                initialSetup={true}
                requireItems={false}
                inputPlaceholder={language["treatment-setup-placeholder"]}
                stepIdentifier="Treatments"
                concept="Treatments"/>
        case "symptom-edit":
            return <SetupStep
                title={language["symptom-setup-title"]}
                description={language["symptom-edit-description"]}
                doneButtonText={language["symptom-edit-done"]}
                initialSetup={false}
                requireItems={true}
                inputPlaceholder={language["symptom-setup-placeholder"]}
                concept="Symptoms"/>
        case "treatment-edit":
            return <SetupStep
                title={language["treatment-setup-title"]}
                description={language["treatment-edit-description"]}
                doneButtonText={language["treatment-edit-done"]}
                initialSetup={false}
                requireItems={false}
                inputPlaceholder={language["treatment-setup-placeholder"]}
                concept="Treatments"/>
    }
    return <div></div>;
}

let root = document.getElementById('remote-root');
if (root) {
    ReactDOM.render(router(root.className), root);
}