let strings: { [key: string]: string } = {
    "login": "Login",
    "back-button": "Back",
    "setting-up-h1": "Setting up your account",
    "setting-up-p1": "Please check back soon!",
    "waitlisted-h1": "Waitlisted",
    "waitlisted-p1": "You are not eligible at this time, but we will contact you if the study opens up.",
    "waitlist-reeligibility": "Please complete the \"Reasess Eligibility\" survey,  below to determine if you are now eligibile for the study.",
    "please-complete-consent": "Please complete consent",
    "complete-consent-p1": "To officially enroll in this study, please complete consent.",
    "complete-consent": "Complete Consent",
    "enrolled-h1": "You\'re enrolled!",
    "enrolled-p1": "Congratulations! You\'re officially enrolled in the STOP COVID-19 study.",
    "welcome-h1": "Welcome to the STOP COVID-19 Study",
    "no-testing-required-h1": "Monitor your symptoms",
    "no-testing-required-pretesting-p1": "Begin using your study dashboard to monitor your symptoms/exposure.",
    "no-testing-required-acuteinfection-p1": "Record your symptoms and exposure daily. You will begin testing in ",
    "no-testing-required-p2": "We have sent test kits to your address for use later in the study.",
    "no-testing-required-p3": "Please complete your pending survey tasks below.",
    "first-test-h1": "Time to begin testing",
    "first-test-p1": "When you receive your test kit, please conduct both an antigen and PCR test and record your results here.",
    "first-test-p2": "Please complete your pending survey tasks below.",
    "next-test-h1": "Time for Your Next Test",
    "next-test-p1": "Please conduct your next test and record the result here.",
    "leave-strip-h1": "Leave the Strip in the Tube",
    "leave-strip-until-ready": "Until Ready to Read",
    "leave-strip-p1": "Do not handle or move the strip until it is ready for reading.",
    "leave-strip-p2": "Once the timer is complete, you will have <span style='color: #EF6C00;'>5 minutes</span> to return to this page to get a valid result.",
    "ready-to-read-h1": "Test Strip Ready to Read",
    "ready-to-read-p1": "It's time to read your test strip and interpret your result.",
    "ready-to-read-button": "Interpret Your Result",
    "test-complete-h1": "Test Complete",
    "test-result-status-p1": "Your reading of the rapid antigen test strip indicates the result was",
    "test-result-status-p2": "Your test result was confirmed as being",
    "test-complete-negative": "Negative for COVID-19.",
    "test-complete-positive": "Positive for COVID-19.",
    "test-complete-invalid": "Invalid.",
    "test-complete-p2": "Continue testing every 48 hours.",
    "test-complete-p3": "Since you tested positive, a member of the study team will be in contact with you. Please continue testing every 48 hours.",
    "study-complete-h1": "​Thank you for participating in this study!",
    "study-complete-p1": "Please remember to complete the surveys. We will send you your participation incentive.",
    "participant-identifier": "Participant ID:",
    "study-week": "Study Week:",
    "record-result-button": "Record Rapid Antigen Test",
    "start-pcr-test": "Start PCR Test",
    "new-risk-h2": "New COVID-19 Symptoms or Exposure?",
    "new-risk-p1": "Select \"Report Symptoms or Exposure\" <strong>only</strong> if you have new COVID-19 symptoms or exposure.",
    "new-risk-button": "Report Symptoms or Exposure",
    "new-test-h2": "Have you taken a COVID-19 Rapid Test?",
    "new-test-p1": "Select \"Report Rapid Antigen Test\" if you have taken an antigen test outside of STOP COVID-19 and would like to report it here.",
    "new-test-button": "Report Rapid Antigen Test",
    "testing-journal-h2": "Testing Journal",
    "testing-journal-all": "View All",
    "connect-fitbit-h2": "Connect Fitbit",
    "connect-fitbit-p1": "If you have a Fitbit, share data from it to help us study how your activity changes when you do not feel well.",
    "connect-fitbit-button": "Connect Fitbit",
    "surveys-h2": "Surveys",
    "study-hotline": "Study Hotline",
    "hotline-hours": "Weekdays 9 AM - 5 PM EST",
    "off-hours-1a": "24/7, Response Next Business Day",
    "download-app-h2": "Download the App",
    "footer-powered-by": "Powered by",
    "footer-credits": "A cooperative effort from state and local health departments, the  <a href=\"https://nih.gov\">National Institutes of Health</a>",
    "footer-company": "© 2023 <a href=\"https://www.careevolution.com\">CareEvolution</a> &bull; <a class=\"privacy-policy\" href=\"https://careevolution.com/AppPrivacyPolicy.html\">Privacy</a>",
    "manage-symptom-log-title": "Manage Symptom Log",
    "edit-symptoms-title": "Edit Symptoms",
    "active-symptoms-subtitle": "Active Symptoms",
    "edit-treatments-title": "Edit Treatments",
    "active-treatments-subtitle": "Active Treatments",
    "symptom-setup-title": "What symptoms do you want to log?",
    "symptom-setup-description": "You can choose what symptoms you want to log.  You can also come back and edit this list later.",
    "symptom-setup-done": "Next: Treatments",
    "symptom-setup-placeholder": "Symptom name, e.g. Headache",
    "symptom-edit-description": "Select 'Options' to change symptom color, enable logging symptom severity or to mute a symptom.",
    "symptom-edit-done": "Save Symptoms",
    "treatment-setup-title": "What treatments do you want to log?",
    "treatment-setup-description": 'Treatments can be anything you do to help reduce your symptoms.  For best results, focus on "as needed" treatments and not daily medications or things that you always do each day.',
    "treatment-setup-done": "Next: Reminders",
    "treatment-setup-placeholder": "Treatment name, e.g. Tylenol",
    "treatment-edit-description": 'Select "Options" to change treatment color or to mute a treatment.',
    "treatment-edit-done": "Save Treatments",
    "update-address-h2": "Update Address",
    "update-address-p1": "Update your shipping address for study materials and your gift card.",
    "update-address-p1-v3": "Update your shipping address for study materials.",
    "update-address-button": "Update Address",
    "days": " days.",
    "earn-rewards-h2" : "My Compensation Badges",
    "earn-rewards-p1" : "Complete two out of the following three objectives (worth $50 each) to earn a ",
    "earn-rewards-p1-v3" : "Complete the following objective to earn $50 in study compensation.",
    "earn-rewards-p2" : "MAXIMUM",
    "earn-rewards-p2-v3" : "Complete one of the following three objectives to earn an additional $50 in study compensation.",
    "earn-rewards-p3" : " of $100 in study compensation.",
    "achievement-baseline-title": "Complete all the baseline surveys to earn this badge.",
    "achievement-baseline-description": "You will need to complete all of the following surveys: Demographics, Healthcare Utilization, Health Status and Medical History, and Covid Beliefs.",
    "achievement-6-title": "Complete 6 weeks of Symptoms and Exposure Surveys",
    "achievement-6-description": "Log your Symptoms and Exposure for 6 weeks during your study to earn this badge.",
    "achievement-9-title": "Complete 9 weeks of Symptoms and Exposure Surveys",
    "achievement-9-description": "Log your Symptoms and Exposure for 9 weeks during your study to earn this badge.",
    "achievement-device-title": "Share Wearable Data",
    "achievement-device-description": "Share your wearable device (Apple Watch, Fitbit, Google Fit) with STOP COVID-19",
    "achievement-sameday-title": "Complete Same Day Testing",
    "achievement-sameday-description": "Complete symptom reporting, audio sample, antigen test, lucira test, and PCR sample on the same day within 1 week of symptom onset.",
    "myachievement-title": "Compensation Badges",
    "start-lucira-test": "Start LUCIRA Test",
    "first-test-p1-v3": "When you receive your test kit, please conduct an antigen test, LUCIRA test, and PCR test and record your results here.",
    "no-testing-required-acuteinfection-p1-v3": "Record your symptoms and exposure daily. You will test again in ",
};

export default strings;