import React from "react";
import './TestResultRow.css'
import '../Core.css'
import {TestResult} from "../types/TestResult";
import LocalizedString from "./LocalizedString";

const moment = require('moment');

export interface TestResultRowProps {
    testResult: TestResult;
}

export default function (props: TestResultRowProps) {
    return <div className="test-result-row">
        {!props.testResult.readStatus &&
        <div className="test-result">
            {props.testResult.result === 'Negative' &&
            <div className="color-good"><LocalizedString stringName="test-complete-negative"/></div>
            }
            {props.testResult.result === 'Positive' &&
            <div className="color-warning"><LocalizedString stringName="test-complete-positive"/></div>
            }
            {props.testResult.result === 'Invalid' &&
            <div className="color-caution"><LocalizedString stringName="test-complete-invalid"/></div>
            }
        </div>
        }
        <div>
            <div className="test-result-date">{moment(props.testResult.testStripAddTime).format("M/D/YY")}</div>
            <div className="test-result-time">{moment(props.testResult.testStripAddTime).format("h:mm a")}</div>
        </div>
    </div>;
}