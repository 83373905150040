import React, {useEffect} from "react";
import './TestResultRow.css'
import '../Core.css'
import MyDataHelps, {DeviceDataPoint, Guid, ParticipantInfo, PersistableDeviceDataPoint, SurveyAnswer} from "@careevolution/mydatahelps-js";

const moment = require('moment');

export interface DailyLogEntryReference {
    id: string;
    severity?: number;
}

export interface DailyLogEntry {
    symptoms: DailyLogEntryReference[];
    treatments: DailyLogEntryReference[];
    notes: string;
    overallFeeling?: number;
}

export interface Severity {
    surveyResultID: Guid;
    symptomName: string;
    severity: number;
}

export interface Symptom {
    id: string;
    name: string;
}

export interface SymptomAnswer {
    date: string;
    value: string;
    severity?: number;
}

export interface SymptomLogSyncProps {
    participantInfo?: ParticipantInfo;
}

export default function (props: SymptomLogSyncProps) {
    if (!props.participantInfo) return null;

    const loadDailyLogEntries = (): Promise<PersistableDeviceDataPoint[]> => {
        let entries: DeviceDataPoint[] = [];
        let getPage = function (pageID?: Guid): Promise<DeviceDataPoint[]> {
            return MyDataHelps.queryDeviceData({
                namespace: "Project",
                type: "DailyLogEntry",
                observedAfter: moment().startOf('day').add(-7, 'days').toISOString(),
                pageID: pageID
            }).then(function (response) {
                entries = entries.concat(response.deviceDataPoints);
                if (response.nextPageID) {
                    return getPage(response.nextPageID);
                } else {
                    return entries;
                }
            });
        };
        return getPage();
    };

    const loadSymptomAnswers = (): Promise<SymptomAnswer[]> => {
        let answers: SurveyAnswer[] = [];

        const getPage = (pageID?: Guid): Promise<SurveyAnswer[]> => {
            return MyDataHelps.querySurveyAnswers({
                surveyName: ["SymptomsAndExposure"],
                stepIdentifier: ["WeeklySymptoms", "WeeklySymptomsSeverity"],
                after: moment().startOf('day').add(-7, 'days').toISOString(),
                pageID: pageID
            }).then(function (response) {
                answers = answers.concat(response.surveyAnswers);
                if (response.nextPageID) {
                    return getPage(response.nextPageID);
                } else {
                    return answers;
                }
            });
        };

        const translateSeverity = (severity: string): number => {
            if (severity === 'Severe') {
                return 10;
            } else if (severity === 'Moderate') {
                return 5;
            } else if (severity === 'Mild') {
                return 2;
            } else {
                return 0;
            }
        };

        return getPage().then(answers => {
            let severities: Severity[] = [];
            answers.filter(answer => answer.stepIdentifier === 'WeeklySymptomsSeverity').forEach(answer => {
                severities.push({
                    surveyResultID: answer.surveyResultID,
                    symptomName: answer.resultIdentifier.replace('Severity', ''),
                    severity: translateSeverity(answer.answers[0])
                })
            });

            let symptomAnswers: SymptomAnswer[] = [];
            answers.filter(answer => answer.stepIdentifier === 'WeeklySymptoms').forEach(answer => {
                answer.answers.filter(answerValue => answerValue !== 'None').forEach(answerValue => {
                    symptomAnswers.push({
                        date: answer.date,
                        value: answerValue,
                        severity: severities.find(severity => severity.surveyResultID === answer.surveyResultID && severity.symptomName === answerValue)?.severity
                    });
                });
            });

            return symptomAnswers;
        });
    };

    useEffect(() => {
        let symptomsJson = props.participantInfo?.customFields["Symptoms"];
        if (symptomsJson) {
            let symptoms = JSON.parse(symptomsJson) as Symptom[];
            loadDailyLogEntries().then(entries => {
                loadSymptomAnswers().then(answers => {
                    answers = answers.sort((a, b) => moment(a.date).diff(moment(b.date)));

                    let dataPointsToSave: PersistableDeviceDataPoint[] = [];
                    answers.forEach(answer => {
                        let matchingSymptom = symptoms.find(symptom => symptom.name === answer.value);
                        if (matchingSymptom) {
                            let matchingDataPoint = entries.find(e => moment(e.observationDate).isSame(moment.utc(answer.date).startOf('day')));
                            if (matchingDataPoint) {
                                let entry = JSON.parse(matchingDataPoint.value) as DailyLogEntry;
                                let existingSymptom = entry.symptoms.find(symptom => symptom.id === matchingSymptom!.id);
                                if (!existingSymptom) {
                                    entry.symptoms.push({id: matchingSymptom.id, severity: answer.severity})
                                    matchingDataPoint.value = JSON.stringify(entry);
                                    if (!dataPointsToSave.find(dp => dp.observationDate === matchingDataPoint!.observationDate)) {
                                        dataPointsToSave.push(matchingDataPoint);
                                    }
                                } else if (existingSymptom.severity !== answer.severity) {
                                    existingSymptom.severity = answer.severity;
                                    matchingDataPoint.value = JSON.stringify(entry);
                                    if (!dataPointsToSave.find(dp => dp.observationDate === matchingDataPoint!.observationDate)) {
                                        dataPointsToSave.push(matchingDataPoint);
                                    }
                                }
                            } else {
                                let newEntry: DailyLogEntry = {
                                    symptoms: [{id: matchingSymptom.id, severity: answer.severity}],
                                    treatments: [],
                                    notes: ''
                                }
                                let newEntryDataPoint: PersistableDeviceDataPoint = {
                                    observationDate: moment.utc(answer.date).startOf('day').toISOString(),
                                    type: "DailyLogEntry",
                                    value: JSON.stringify(newEntry)
                                };
                                entries.push(newEntryDataPoint);
                                if (!dataPointsToSave.find(dp => dp.observationDate === newEntryDataPoint.observationDate)) {
                                    dataPointsToSave.push(newEntryDataPoint);
                                }
                            }
                        }
                    });
                    if (dataPointsToSave.length > 0) {
                        MyDataHelps.persistDeviceData(dataPointsToSave).then();
                    }
                });
            });
        }
    }, []);

    return null;
}