let strings: { [key: string]: string } = {
    "login": "Iniciar sesión",
    "back-button": "Volver atrás",
    "setting-up-h1": "Cómo configurar su cuenta",
    "setting-up-p1": "¡Por favor vuelva a revisar!",
    "waitlisted-h1": "En lista de espera",
    "waitlisted-p1": "En este momento, usted no es elegible.",
    "waitlist-reeligibility": "Por favor complete el cuestionario de \"Revaluación de elegibilidad\"",
    "please-complete-consent": "Por favor complete el formulario de consentimiento",
    "complete-consent-p1": "Para inscribirse oficialmente en este estudio, por favor complete el formulario de consentimiento",
    "complete-consent": "Complete el formulario de consentimiento",
    "enrolled-h1": "¡Ya está inscrito!",
    "enrolled-p1": "¡Felicitaciones! Está inscrito oficialmente en el estudio STOP COVID-19.",
    "welcome-h1": "Bienvenidos al Estudio STOP COVID-19",
    "no-testing-required-h1": "Controlar sus síntomas",
    "no-testing-required-pretesting-p1": "Comience a usar el tablero de estudio para controlar sus síntomas/exposición.",
    "no-testing-required-acuteInfection-p1": "Registre sus síntomas y exposición diariamente. Comenzará a probar en ",
    "no-testing-required-p2": "Hemos enviado kits de pruebas a su dirección para que los use más adelante en el estudio.",
    "no-testing-required-p3": "Favor de completar sus tareas pendientes de la encuesta a continuación.",
    "first-test-h1": "Es hora de comenzar a probar",
    "first-test-p1": "Cuando reciba su kit de prueba, realice una prueba rápida de antigenos y una prueba PCR y registre sus resultados aquí.",
    "first-test-p2": "Por favor, complete sus tareas pendientes de la encuesta a continuación.",
    "next-test-h1": "Ahora es momento de hacerse su próxima prueba",
    "next-test-p1": "Realice su siguiente prueba y registre el resultado aquí.",
    "leave-strip-h1": "Deje la Tira en el Tubo",
    "leave-strip-until-ready": "Hasta que esté lista para leer el resultado",
    "leave-strip-p1": "No manipule ni mueva la tira hasta que esté lista para leer el resultado.",
    "leave-strip-p2": "Una vez que termine el temporizador, usted tiene <span style='color: #EF6C00;'>5 minutos</span> para volver a esta página para obtener un resultado válido.",
    "ready-to-read-h1": "La tira de la prueba está lista para leer el resultado",
    "ready-to-read-p1": "Llegó el momento de leer la tira de su prueba y de interpretar el resultado.",
    "ready-to-read-button": "Interpretar el resultado",
    "test-complete-h1": "Prueba Realizada",
    "test-result-status-p1": "La lectura de su tira reactiva de prueba rápida de antígenos indica que el resultado fue",
    "test-result-status-p2": "Se confirmó que el resultado de su prueba dio",
    "test-complete-negative": "Negativo for COVID-19.",
    "test-complete-positive": "Positivo for COVID-19.",
    "test-complete-invalid": "Inválido.",
    "test-complete-p2": "Continuar a probar cada 48 horas.",
    "test-complete-p3": "Dado que usted dio positivo, un miembro del equipo del estudio se pondrá en contacto con usted. Por favor, continúe probar cada 48 horas.",
    "study-complete-h1": "​¡Gracias por participar en este estudio científico!",
    "study-complete-p1": "Por favor recuerde completar los cuestionarios. Le enviaremos su incentivo por participar.",
    "participant-identifier": "Identificación del participante:",
    "study-week": "Semana:",
    "record-result-button": "Reportar Prueba Rápida de Antígenos",
    "start-pcr-test": "Iniciar prueba PCR",
    "new-risk-h2": "¿Tiene síntomas recientes de COVID-19 o fue expuesto?",
    "new-risk-p1": "Elija \"Informar síntomas o exposición\" <strong>solamente</strong> si usted tiene síntomas recientes de COVID-19 o si estuvo expuesto a alguien con COVID-19.",
    "new-risk-button": "Informar síntomas o exposición",
    "new-test-h2": "¿Ha realizado una prueba rápida de antígenos para COVID-19?",
    "new-test-p1": "Seleccione \"Reportar Prueba Rápida de Antígenos\" si se ha realizado una prueba de antígenos afuera del estudio STOP COVID-19 y desea reportarla aquí.",
    "new-test-button": "Reportar Prueba Rápida de Antígenos",
    "testing-journal-h2": "Diario de pruebas",
    "testing-journal-all": "Ver todo",
    "connect-fitbit-h2": "Conectar su Fitbit",
    "connect-fitbit-p1": "Si tiene un Fitbit, comparta la información de su accesorio para ayudarnos a estudiar cómo cambia su actividad cuando no se siente bien.",
    "connect-fitbit-button": "Conectar su Fitbit",
    "surveys-h2": "Cuestionarios",
    "study-hotline": "Línea de asistencia del estudio",
    "hotline-hours": "Miércoles de 9:00 a. m. a 5:00 p. m. EST",
    "off-hours-1a": "24 horas/7 días, respuesta al siguiente día laborable",
    "download-app-h2": "Descargar la aplicación",
    "footer-powered-by": "Diseñada por",
    "footer-credits": "Una iniciativa de cooperación entre los departamentos de salud locales y estatal, los <a href=\"https://nih.gov\">Institutos Nacionales de Salud (NIH)</a>, y los <a href=\"https://cdc.gov\">Centros para el Control y la Prevención de Enfermedades (CDC)</a>",
    "footer-company": "© 2023 <a href=\"https://www.careevolution.com\">CareEvolution</a> &bull; <a class=\"privacy-policy\" href=\"https://careevolution.com/AppPrivacyPolicy.html\">Privacy</a>",
    "manage-symptom-log-title": "Administrar registro de síntomas",
    "edit-symptoms-title": "Editar síntomas",
    "active-symptoms-subtitle": "síntomas activos",
    "edit-treatments-title": "Editar tratamientos",
    "active-treatments-subtitle": "tratamientos activos",
    "symptom-setup-title": "¿Qué síntomas desea registrar?",
    "symptom-setup-description": "You can choose what symptoms you want to log.  You can also come back and edit this list later.",
    "symptom-setup-done": "Siguiente: Tratamientos",
    "symptom-setup-placeholder": "Nombre del síntoma, p. ej., dolor de cabeza",
    "symptom-edit-description": "Seleccione \"Opciones\" para cambiar el color de los síntomas, activar el registro de la gravedad de los síntomas o silenciar un síntoma.",
    "symptom-edit-done": "Guardar los síntomas",
    "treatment-setup-title": "¿Qué tratamientos desea registrar?",
    "treatment-setup-description": 'Los tratamientos pueden ser cualquier cosa que haga para ayudar a reducir sus síntomas. Para obtener los mejores resultados, céntrese en los tratamientos "según sea necesario" y no en los medicamentos diarios o cosas que siempre hace cada día.',
    "treatment-setup-done": "Siguiente: Recordatorios",
    "treatment-setup-placeholder": "Nombre del tratamiento, p. ej., Tylenol",
    "treatment-edit-description": 'Seleccione "Opciones" para cambiar el color del tratamiento o para silenciar un tratamiento.',
    "treatment-edit-done": "Guardar tratamientos",
    "update-address-h2": "Cambiar Su Dirección",
    "update-address-p1": "Cambiar su dirección de envio para materiales de estudio y su tarjeta de regalo.",
    "update-address-p1-v3": "Cambiar su dirección de envio para materiales de estudio.",
    "update-address-button": "Cambiar Su Dirección",
    "days": " días.",
    "earn-rewards-h2" : "Mis Insignias de Compensación",
    "earn-rewards-p1" : "Complete dos de los siguientes tres objetivos (valorados en $50 cada uno) para ganar un ",
    "earn-rewards-p1-v3" : "Complete el siguiente objetivo para ganar $50 en compensación del estudio.",
    "earn-rewards-p2" : "MÁXIMO",
    "earn-rewards-p2-v3" : "Complete uno de los siguientes tres objetivos para ganar $50 adicional en compensación del estudio.",
    "earn-rewards-p3" : " de $100 en compensación del estudio.",
    "achievement-baseline-title": "TODO: Complete all the baseline surveys to earn this badge.",
    "achievement-baseline-description": "TODO: You will need to complete all of the following surveys: Demographics, Healthcare Utilization, Health Status and Medical History, and Covid Beliefs.",
    "achievement-6-title": "Completar 6 semanas de encuestas de síntomas y exposición",
    "achievement-6-description": "Registre sus síntomas y exposición por 6 semanas durante su estudio para obtener esta insignia.",
    "achievement-9-title": "Completar 9 semanas de encuestas de síntomas y exposición",
    "achievement-9-description": "Registre sus síntomas y exposición por 9 semanas durante su estudio para obtener esta insignia.",
    "achievement-device-title": "Compartir Datos del Dispositivo Vestible",
    "achievement-device-description": "Comparta su dispositivo vestible (Apple Watch, Fitbit, Google Fit), con STOP COVID-19",
    "achievement-sameday-title": "TODO: Complete Same Day Testing",
    "achievement-sameday-description": "TODO: Complete symptom reporting, audio sample, antigen test, lucira test, and PCR sample on the same day within 1 week of symptom onset.",
    "myachievement-title": "Insignias de Compensación",
    "start-lucira-test": "TODO: Start Lucira Test",
    "first-test-p1-v3": "TODO: When you receive your test kit, please conduct an antigen test, Lucira test, and PCR test and record your results here.",
    "no-testing-required-acuteinfection-p1-v3": "TODO: Record your symptoms and exposure daily. You will test again in ",
};

export default strings;