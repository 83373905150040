import React, {useEffect, useState} from 'react'
import "./Achievements.css";
import Achievement from "./Achievement";
import { faCalendarDays, faClipboard, faListCheck, faSquarePollHorizontal, faUserDoctor , faClock} from "@fortawesome/pro-solid-svg-icons";
import { faWatchFitness } from "@fortawesome/pro-light-svg-icons";
import MyDataHelps, { ParticipantInfo } from "@careevolution/mydatahelps-js";
import language from "../helpers/Language"

const debounce = require('lodash.debounce');
const moment = require('moment');

export type AchievementsMode = 'compact' | 'verbose';

export interface AchievementsProps {
    mode: AchievementsMode;
}

export default function (props: AchievementsProps) {
    let [loading, setLoading] = useState<boolean>(true);

    let [baselineSurveyCount, setBaselineSurveyCount] = useState<number>();

    const getCount = (participantInfo: ParticipantInfo, countCustomFieldName: string): number => {
        if (participantInfo.customFields.hasOwnProperty(countCustomFieldName)) {
            let count = participantInfo.customFields[countCustomFieldName];
            return !!count ? parseInt(count) : 0;
        }
        return 0;
    };

    const loadParticipantInfo = (): Promise<void> => {
        return MyDataHelps.getParticipantInfo().then((participantInfo) => {
            setBaselineSurveyCount(getCount(participantInfo, 'BaselineSurveysCompleted'));
        });
    };

    const initialize = () => {
        setLoading(true);
        loadParticipantInfo().then(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    return <div className={props.mode === 'compact' ? 'achievements-compact-v3' : 'achievements-verbose'}>
        <Achievement mode={props.mode} loading={loading} numerator={baselineSurveyCount!} bronze={4} silver={4} gold={4} icon={faClipboard} title={language['achievement-baseline-title']} description={language['achievement-baseline-description']}/>
    </div>;
}