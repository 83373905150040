import React from "react";
import './NavigationBar.css'
import '../Core.css'
import LocalizedString from "./LocalizedString";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface NavigationBarProps {
    titleKey: string;
    onBack: () => void;
}

export default function (props: NavigationBarProps) {
    return <div className="navigation-bar">
        <FontAwesomeIcon icon={faChevronLeft} className="navigation-bar-back-icon"/>
        <div className="navigation-bar-back" onClick={() => props.onBack()}><LocalizedString stringName="back-button"/></div>
        <div className="navigation-bar-title"><LocalizedString stringName={props.titleKey}/></div>
    </div>;
}