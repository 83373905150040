import React, {useEffect, useState} from 'react'
import './TestResults.css';
import '../Core.css';
import MyDataHelps, {ParticipantInfo} from '@careevolution/mydatahelps-js';
import TestResultList from "../component/TestResultList";
import NavigationBar from "../component/NavigationBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";

const debounce = require('lodash.debounce');

export default function () {
    const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>();

    const loadParticipantInfo = (): void => {
        MyDataHelps.getParticipantInfo().then(function (participantInfo) {
            setParticipantInfo(participantInfo);
        });
    };

    const initialize = () => {
        loadParticipantInfo();
    };

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    const onBack = () => {
        MyDataHelps.back();
    }

    return <div className="test-results">
        <NavigationBar titleKey="testing-journal-h2" onBack={() => onBack()}/>
        {participantInfo &&
        <TestResultList participantInfo={participantInfo}/>
        }
        {!participantInfo &&
        <div className="loading-indicator">
            <FontAwesomeIcon icon={faRefresh} spin={true}/>
        </div>
        }
    </div>
}