import React from 'react'
import { Layout } from '@careevolution/mydatahelps-ui'
import MyDataHelps from '@careevolution/mydatahelps-js';
import Achievements from '../component/Achievements';
import './MyAchievements.css';
import NavigationBar from '../component/NavigationBar';
import LocalizedString from "../component/LocalizedString";

export default function () {
    const bronzeColor: string = '#cd7f32';
    const silverColor: string = '#bcc6cc';
    const goldColor: string = '#ffd700';

    const onBack = () => {
      MyDataHelps.back();
    }

    return (
        <Layout bodyBackgroundColor="#fff">
            <div className='achievement-module'>
                <NavigationBar titleKey="myachievement-title" onBack={() => onBack()}/>
                <div className="my-achievements">
                    <p><LocalizedString stringName="earn-rewards-p1"/><b><LocalizedString stringName="earn-rewards-p2"/></b><LocalizedString stringName="earn-rewards-p3"/></p>
                    <Achievements mode='verbose'/>
                </div>
            </div>
        </Layout>
    )
}