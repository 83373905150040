import React, {useEffect, useState} from 'react'
import "./Achievements.css";
import Achievement from "./Achievement";
import { faCalendarDays, faListCheck, faSquarePollHorizontal, faUserDoctor , faVialVirus} from "@fortawesome/pro-solid-svg-icons";
import { faWatchFitness } from "@fortawesome/pro-light-svg-icons";
import MyDataHelps, { ParticipantInfo } from "@careevolution/mydatahelps-js";
import language from "../helpers/Language"

const debounce = require('lodash.debounce');
const moment = require('moment');

export type AchievementsMode = 'compact' | 'verbose';

export interface AchievementsProps {
    mode: AchievementsMode;
}

export default function (props: AchievementsProps) {
    let [loading, setLoading] = useState<boolean>(true);

    let [logCount, setLogCount] = useState<number>();
    let [deviceDataCount, setDeviceDataCount] = useState<number>();
    let [sameDayTestingReward, setSameDayTestingReward] = useState<number>();


    const getDeviceCount = (participantInfo: ParticipantInfo, badgeCustomFieldName: string): number => {
        if (participantInfo.customFields.hasOwnProperty(badgeCustomFieldName) && participantInfo.customFields[badgeCustomFieldName] === 'true') {
            return 1;
        }
        return 0;
    };

    const getSameDayTestingReward = (participantInfo: ParticipantInfo, badgeCustomFieldName: string): number => {
        if (participantInfo.customFields.hasOwnProperty(badgeCustomFieldName) && participantInfo.customFields[badgeCustomFieldName] != "") {
            return 1;
        }
        return 0;
    };

    const getCount = (participantInfo: ParticipantInfo, countCustomFieldName: string): number => {
        if (participantInfo.customFields.hasOwnProperty(countCustomFieldName)) {
            let count = participantInfo.customFields[countCustomFieldName];
            return !!count ? parseInt(count) : 0;
        }
        return 0;
    };

    const loadParticipantInfo = (): Promise<void> => {
        return MyDataHelps.getParticipantInfo().then((participantInfo) => {
            setLogCount(getCount(participantInfo, 'LogWeeks'));
            setDeviceDataCount(getDeviceCount(participantInfo, 'ConnectedDevice'));
            setSameDayTestingReward(getSameDayTestingReward(participantInfo, 'SameDayTestingReward'))
        });
    };

    const initialize = () => {
        setLoading(true);
        loadParticipantInfo().then(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    return <div className={props.mode === 'compact' ? 'achievements-compact-v3' : 'achievements-verbose'}>
        <Achievement mode={props.mode} loading={loading} numerator={logCount!} bronze={6} silver={6} gold={6} icon={faCalendarDays} title={language['achievement-6-title']} description={language['achievement-6-description']}/>
        <Achievement mode={props.mode} loading={loading} numerator={deviceDataCount!} bronze={1} silver={1} gold={1} icon={faWatchFitness} title={language['achievement-device-title']} description={language['achievement-device-description']}/>
        <Achievement mode={props.mode} loading={loading} numerator={sameDayTestingReward!} bronze={1} silver={1} gold={1} icon={faVialVirus} title={language['achievement-sameday-title']} description={language['achievement-sameday-description']}/>
    </div>;
}